import React from 'react';

import {Layout} from '../components/layout';

export default ({data}: any) => (
    <Layout title="404 Pagina niet gevonden">
        <section className="section">
            <article className="content content--no-hero">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            Niet <br />
                            gevonden
                        </h2>
                        <div className="display__number">404</div>
                    </div>
                </div>
            </article>
        </section>
    </Layout>
);
